<template>
  <section>
    <AuthWrapper>
      <template #heading>
        <SecurityLogo />
      </template>

      <template #card>
        <div>
          <div class="text-center mb-2">
            <h3>
              Success
            </h3>
          </div>
          <div class="text-center">
            <Check />
          </div>
          <div class="my-3 text-center">
            <h6>MFA Removed from account successfully</h6>
          </div>
          <div class="text-center">
            <b-link :to="{ name:'auth-login' }">
              <BButton variant="primary" block>
                Login
              </BButton>
            </b-link>
          </div>
        </div>
      </template>
    </AuthWrapper>
  </section>
</template>

<script>
import Check from "@/assets/svg/Check.svg";
import { BButton, BLink } from "bootstrap-vue";

import SecurityLogo from "@/pages/auth/shared-components/SecurityLogo.vue";
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";

export default {
  name: "Success",
  components: { Check, SecurityLogo, AuthWrapper, BButton, BLink }
}
</script>

<style scoped>

</style>
